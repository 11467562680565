import Image from 'next/legacy/image';

import { PMPKeyVisualViewProps } from 'lib/themes/themes';
import { getBannerImageUrl } from 'lib/util/imageUrl';

const PMPKeyVisualView = ({
  bannerImageUrl,
  bannerTitle,
  bannerDescription,
}: PMPKeyVisualViewProps) => {
  return (
    <div className="base-top">
      <Image
        priority={true}
        className="base-top__pic"
        layout="fill"
        src={bannerImageUrl}
        loader={({ src, width }) => getBannerImageUrl(src, width)}
        alt="banner"
      />
      <div className="base-top__body">
        <div className="base-top__body__headline">{bannerTitle}</div>
        <p className="base-top__body__description">{bannerDescription}</p>
      </div>
    </div>
  );
};

export default PMPKeyVisualView;
